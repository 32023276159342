import * as THREE from 'three'
import Experience from '../Experience.js'
import Environment from './Environment.js'
import DebugHelpers from "./DebugHelpers.js";
import Ui from "../Ui/Ui.js";
import State from "../State.js";
import Time from "../Utils/Time.js";

import ExampleClass from "./ExampleClass.js";
import SphereGlass from "./SphereGlass.js";
import Flows from "@experience/World/Flows.js";
import ParticlesSimulation from "@experience/World/ParticlesSimulation.js";
import Particles from "@/three/Experience/World/Particles.js";
import Cube from "@experience/World/Cube.js";
import Cards from "@experience/World/Cards.js";

import SceneDepth from "@experience/World/SceneDepth/SceneDepth.js";
import EventEmitter from '@/three/Experience/Utils/EventEmitter.js';

export default class World extends EventEmitter {
    constructor() {
        super()

        this.experience = Experience.getInstance()
        this.ui = new Ui()
        this.time = Time.getInstance()

        this.camera = this.experience.camera;
        this.scene = this.experience.scene

        this.resources = this.experience.resources
        this.html = this.experience.html
        this.sound = this.experience.sound
        this.debug = this.experience.debug.panel

        // Wait for resources
        this.resources.on( 'ready', () => {
            this.state = new State()

            //this.startWithPreloader()
            this.start()
        } )
    }

    start() {
        // setTimeout( () => {
        //     window.preloader.hidePreloader()
        // }, 1000)

        this.time.reset()

        this.setupWorld()

        //this.animationPipeline();

        this.trigger( 'ready' )
        window.dispatchEvent( new CustomEvent( "3d-app:ready" ) );
    }

    setupWorld() {
        // Setup
        //this.cube = new ExampleClass()



        this.flowsDown = new Flows(
            {
                pointA: new THREE.Vector3(-2.0, -2.0, 2.0),
                pointB: new THREE.Vector3(-4.0, 1.5, 2.0),
                controlPoint1: new THREE.Vector3(2.0, 0.0, 2.0),
                controlPoint2: new THREE.Vector3(2.0, 0.0, 2.0),
                uSpeed: 0.068,
                uPerlinMultiplier: 0.780,
                uPerlinFrequency:  2.0,
                uTimeFrequency: 0.0,
            }
        )

        this.cube = new Cube()
        this.sphereGlass = new SphereGlass()
        this.cards = new Cards()


        this.flowsUp = new Flows(
            {
                pointA: new THREE.Vector3(3.0, 3.0, -2.0),
                pointB: new THREE.Vector3(9.0, -1.5, -2.0),
                controlPoint1: new THREE.Vector3(-2.0, 0.0, -2.0),
                controlPoint2: new THREE.Vector3(-2.0, 0.0, -2.0),
                uSpeed: 0.068,
                uPerlinMultiplier: 0.780,
                uPerlinFrequency:  2.0,
                uTimeFrequency: 0.0,
            }
        )

        this.particlesSimulation = new ParticlesSimulation()
        this.particles = new Particles()


        this.environment = new Environment()

        // Scenes

        this.sceneDepth = new SceneDepth()

        this.sceneDepth.container.add( this.flowsUp?.points.clone(), this.flowsDown?.points.clone() )

        // Add debug helpers
        this.debugHelpers = new DebugHelpers()


        // Animation timeline
        this.animationPipeline();

        // Dispatch event
        this.experience.appLoaded = true
    }

    startWithPreloader() {
        this.ui.playButton.classList.add( "fade-in" );
        this.ui.playButton.addEventListener( 'click', () => {

            this.ui.playButton.classList.replace( "fade-in", "fade-out" );
            //this.sound.createSounds();

            setTimeout( () => {
                this.time.reset()

                // Setup
                this.setupWorld()

                // Remove preloader
                this.ui.preloader.classList.add( "preloaded" );
                setTimeout( () => {
                    this.ui.preloader.remove();
                    this.ui.playButton.remove();
                }, 2500 );
            }, 100 );
        }, { once: true } );
    }

    animationPipeline() {
        // if ( this.text )
        //     this.text.animateTextShow()

        this.camera?.animateCameraPosition()
        this.particles?.animation()
        this.cube?.animation()
        this.sphereGlass?.setAnimation()
        this.cards?.setAnimations()
    }

    resize() {
        this.state?.resize()
        this.sphereGlass?.resize()
        this.particles?.resize()
        this.particlesSimulation?.resize()
        this.cube?.resize()
        this.cards?.resize()

        this.sceneDepth?.resize()
    }

    update( deltaTime ) {
        this.debugHelpers?.update( deltaTime )
        this.cube?.update( deltaTime )
        this.sphereGlass?.update( deltaTime )
        this.particles?.update( deltaTime )
        this.particlesSimulation?.update( deltaTime )
        this.flowsUp?.update( deltaTime )
        this.cube?.update( deltaTime )
        this.cards?.update( deltaTime )
        this.flowsDown?.update( deltaTime )
    }

    postUpdate( deltaTime ) {
        this.sphereGlass?.postUpdate( deltaTime )
        this.sceneDepth?.postUpdate( deltaTime )
    }
}
