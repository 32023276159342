<template>
	<section id="project" class="home-projects"></section>
</template>

<script>
import Experience from '@experience/Experience.js';
import { mapActions } from 'pinia';
import { useAppStore } from '@/stores/app.js';
import projects from '@/data/projects.json';

export default {
	name: 'HomeProjects',
	mounted() {
		window.addEventListener("3d-add:card-click", (e) => {
			if (window.location.pathname !== '/') return;
			this.experience = Experience.getInstance();
			this.setCircleAnimation(true);
			this.experience.world.cards.openAnimation()
			this.$router.push({ name: 'Project', params: { slug: e.detail.name.toLowerCase() } })
		})

		if (window.scrollToProject) {
			this.setScrollAnimated(false)
			const projectSectionTop = this.$el.offsetTop;
			const projectSectionHeight = this.$el.offsetHeight;
			let projectIndex = projects.findIndex(item => item.slug === window.scrollToProject);
			let scrollPosition = projectSectionTop + projectSectionHeight / projects.length * projectIndex
			if (document.documentElement.classList.contains('mobile-scroll')) {
				document.getElementById('app').scrollTop = scrollPosition;
			} else {
				window.scrollTo({top: scrollPosition, behavior: 'instant'});
			}
			window.scrollToProject = null;

			this.$nextTick(() => {
				this.setCircleAnimation(false);
			})
		}

		setTimeout(() => {
			this.setScrollAnimated(true);
		}, 100)
	},
	methods: {
		...mapActions(useAppStore, ['setScrollAnimated', 'setCircleAnimation'])
	}
};
</script>
