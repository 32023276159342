<template>
	<section :id="currentSection.id" class="home-hero">
		<div ref="container" class="home-hero__container container">
			<div class="home-hero__top">
				<AppPagination :current-section="currentSection" :sections="sections" />

				<div class="home-hero__location">
					<p class="home-hero__location-title m-0">
						<small>Location:</small>
						EMEA and MENA
					</p>

					<div class="home-hero__location-circles">
						<span class="home-hero__location-circle"></span>
						<span class="home-hero__location-circle"></span>
					</div>
				</div>
			</div>

			<h1 ref="title" class="home-hero__title m-0 font-heading center">
				<span class="line">Building <span class="c-primary font-heading-secondary">For</span></span>
				<span class="line"><span class="c-primary font-heading-secondary">The</span> Future</span>
			</h1>

			<hr class="home-hero__line m-0" />

			<div class="home-hero__bottom">
				<div class="home-hero__desc-wrapper">
					<p ref="desc" class="home-hero__desc m-0">
						Experience the full entertainment package at Saguni Studios! From games to digital worlds and stories, our
						passionate team of industry experts is here to redefine your digital experience. Get ready for
						creativity, excellence, and a whole new level of entertainment!
					</p>
				</div>

				<BaseCircleButton button-type="link" :to="{ hash: '#contacts' }" class="home-hero__link"
					>Contacts</BaseCircleButton
				>
			</div>
		</div>
	</section>
</template>

<script>
import AppPagination from '@/components/AppPagination.vue';
import BaseCircleButton from '@/components/BaseCircleButton.vue';
import { gsap } from 'gsap';
import html2canvas from 'html2canvas';
import Sizes from '@experience/Utils/Sizes.js';
import { mapActions, mapState } from 'pinia';
import { useAppStore } from '@/stores/app.js';

export default {
	name: 'HomeHero',
	components: { BaseCircleButton, AppPagination },
	props: {
		currentSection: {
			type: Object,
			required: true,
		},
		sections: {
			type: Array,
			required: true,
		},
	},
	data: () => ({
		screenshotCanvas: null,
		screenshotCanvasCtx: null,
		animationInProgress: false,
		resizeTimeoutId: null,
	}),
	computed: {
		...mapState(useAppStore, ['isHeroAnimationPlayed'])
	},
	mounted() {
		this.sizes = new Sizes();
		window.addEventListener('resize', this.onResize);

		this.screenshotCanvas = document.querySelector('#screenshot-canvas');
		this.screenshotCanvasCtx = this.screenshotCanvas.getContext('2d', { willReadFrequently: true });
		this.displayElementOnCanvas();

		if ( !this.isHeroAnimationPlayed ) {
			this.createTimeline();
			window.addEventListener('3d-app:complete-first-animation', this.onCompleteFirstAnimation);
		}	
	},
	beforeUnmount() {
		window.removeEventListener('3d-app:complete-first-animation', this.onCompleteFirstAnimation);
		window.removeEventListener('resize', this.onResize);
	},
	methods: {
		...mapActions(useAppStore, ['setHeroAnimationPlayed']),
		createTimeline() {
			this.animationInProgress = true;

			const { container, title, desc } = this.$refs;
			const titleLines = title.querySelectorAll('.line');
			this.tl = gsap.timeline({ onComplete: this.clearGsapInlineStyles });

			gsap.set(container, { y: '100vh' });
			titleLines.forEach((item, index) => {
				if (index % 2 === 0) {
					gsap.set(item, { x: '100%' });
				} else {
					gsap.set(item, { x: '-100%' });
				}
			});
			gsap.set(desc, { y: '-20', opacity: 0 });

			this.tl
				.to(container, { y: 0, duration: 1 })
				.to(titleLines, { x: 0, duration: 1 }, 0)
				.to(desc, { y: 0, opacity: 1 }, 1);
			this.tl.pause();
		},
		onCompleteFirstAnimation() {
			if (this.tl) {
				this.tl.resume();
				this.setHeroAnimationPlayed(true);
			}
		},
		displayElementOnCanvas() {
			html2canvas(this.$el, { backgroundColor: null, scale: this.sizes.pixelRatio }).then((canvas) => {
				this.screenshotCanvas.width = canvas.width;
				this.screenshotCanvas.height = canvas.height;

				// fix artefacts on the bottom of the canvas
				this.screenshotCanvas.height = canvas.height + 10;
				this.screenshotCanvasCtx.drawImage(canvas, 0, 0);

				window.dispatchEvent(
					new CustomEvent('3d-app:canvas-text', {
						detail: {
							canvas: canvas,
						},
					}),
				);
			});
		},
		clearGsapInlineStyles() {
			this.animationInProgress = false;
			const { container, title, desc } = this.$refs;
			const titleLines = title.querySelectorAll('.line');

			container.removeAttribute('style');
			titleLines.forEach((item) => item.removeAttribute('style'));
			desc.removeAttribute('style');
		},
		onResize() {
			clearTimeout(this.resizeTimeoutId);
			this.resizeTimeoutId = setTimeout(() => {
				if (this.screenshotCanvas && this.screenshotCanvasCtx) {
					if (this.animationInProgress) {
						const animationInProgressWatcher = this.$watch('animationInProgress', () => {
							this.displayElementOnCanvas();
							animationInProgressWatcher();
						});
					} else {
						this.displayElementOnCanvas();
					}
				}
			}, 100);
		},
	},
};
</script>
