<template>
	<AppLayout :footerVisible="false">
		<ProjectInfo :projectData="projectData" data-progress-step />
	</AppLayout>
</template>

<script>
import AppLayout from '@/components/AppLayout.vue';
import ProjectInfo from '@/components/ProjectView/ProjectInfo.vue';
import projects from '@/data/projects.json';

export default {
	name: 'ProjectView',
	components: { AppLayout, ProjectInfo },
	data: () => ({
		projectData: null
	}),
	props: {
		slug: {
			type: String,
			required: true,
		},
	},
	created() {
		if (this.slug) {
			this.getProjectData(this.slug)
		}
	},
	methods: {
		getProjectData(projectSlug) {
			this.projectData = projects.find(item => item.slug === projectSlug);
		}
	}
};
</script>
