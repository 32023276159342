<template>
	<AppLayout>
		<HomeHero :current-section="getSectionById('hero')" :sections="sections" data-progress-step />
		<HomeProjects data-progress-step />
		<div data-progress-step class="home-projects-after"></div>
		<AboutUs :current-section="getSectionById('about-us')" :sections="sections" data-progress-step />
		<OurClients :current-section="getSectionById('our-clients')" :sections="sections" data-progress-step />
	</AppLayout>
</template>

<script>
import HomeHero from '@/components/HomeView/HomeHero.vue';
import AboutUs from '@/components/AboutUs.vue';
import AppLayout from '@/components/AppLayout.vue';
import OurClients from '@/components/OurClients.vue';
import HomeProjects from '@/components/HomeView/HomeProjects.vue';
import { mapState, mapActions } from 'pinia';
import { useAppStore } from '@/stores/app.js';

export default {
	name: 'HomeView',
	components: { HomeProjects, OurClients, AppLayout, AboutUs, HomeHero },
	data: () => ({
		sections: [
			{
				id: 'hero',
				title: 'Crafting Digital Marvels: Saguni Studios',
			},
			{
				id: 'about-us',
				title: 'Your WEB3 Partner',
			},
			{
				id: 'our-clients',
				title: 'Trusted By',
			},
		],
	}),
	computed: {
		...mapState(useAppStore, ['customScrollProgress', 'customScrollProgressSteps']),
		homeProjectsIsActive() {
			if (this.customScrollProgressSteps) {
				return (
					this.customScrollProgress > this.customScrollProgressSteps[1] &&
					this.customScrollProgress < this.customScrollProgressSteps[2]
				);
			}

			return false;
		},
	},
	watch: {
		homeProjectsIsActive(value) {
			this.toggleGridScroll(!value);
		},
	},
	methods: {
		...mapActions(useAppStore, ['toggleGridScroll']),
		getSectionById(sectionId) {
			return this.sections.find(({ id }) => id === sectionId);
		},
	},
};
</script>
