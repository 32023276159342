import * as THREE from 'three';
import Model from './Abstracts/Model.js';
import Experience from '../Experience.js';
import Debug from '../Utils/Debug.js';
import State from '../State.js';
import Materials from '../Materials/Materials.js';
import FBO from '@experience/Utils/FBO.js';
import { RoomEnvironment } from 'three/addons/environments/RoomEnvironment.js';

import { MathUtils } from 'three';
import * as MathHelper from '@experience/Utils/MathHelper.js';
import * as Helpers from '@experience/Utils/Helpers.js';
import gsap from 'gsap';

export default class Cards extends Model {
	experience = Experience.getInstance();
	debug = Debug.getInstance();
	state = State.getInstance();
	materials = Materials.getInstance();
	fbo = FBO.getInstance();
	input = this.experience.input;
	raycaster = this.input.raycaster;
	scroll = this.input.appScroll;
	sizes = experience.sizes;
	scene = experience.scene;
	time = experience.time;
	camera = experience.camera.instance;
	renderer = experience.renderer.instance;
	resources = experience.resources;
	container = new THREE.Group();
	postProcess = this.experience.postProcess;

	positionScale = 30.0;

	transitionProgress = -0.2;

	cardsCount = 7;


	cardsNames = [
		'Callisto', 'DriveSafe', 'Manga', 'EvoVerse', 'CardGame', 'TabMaster', 'Project-X'
		// 'callisto_text', 'drivesafe_text', 'mangaanime_text', 'evoverse_text', 'card_game_text'
	];

	constructor() {
		super();

		this.setModel();
		this.setModelDepth();
		this.setAnimations();
		this.setEvents();
		this.setDebug();
	}

	setModel() {

		this.model = this.resources.items.cardsModel.scene;
		this.model.scale.setScalar( 3 );
		this.container.position.y = -this.scroll.progressSteps[ 1 ] * this.positionScale;


		this.setTexturesAdaptive();
		this.playAllVideosAdaptive()

		this.postProcess.clearPass.uniforms.u_TransitionTexture.value = this.transitionTexture;
		this.setTransitionTextureResolution();
		this.changeCardsMaterials();

		this.container.add( this.model );

		this.scene.add( this.container );
	}

	setTexturesAdaptive() {
		this.transitionTexture = this.resources.items.transitionTexture;
		this.transitionTexture.wrapS = THREE.RepeatWrapping;
		this.transitionTexture.wrapT = THREE.RepeatWrapping;

		if( this.experience.isMobile ) {
			this.setTextures();

			return
		}

		this.setVideoTextures();
	}

	setTextures() {
		this.callistoTexture = this.resources.items.callistoImageTexture;
		this.callistoTexture.flipY = false;
		this.driveSafeTexture = this.resources.items.driveSafeImageTexture;
		this.driveSafeTexture.flipY = false;
		this.cardGamesTexture = this.resources.items.elementalDuelsImageTexture;
		this.cardGamesTexture.flipY = false;
		this.evoVerseTexture = this.resources.items.evoVerseImageTexture;
		this.evoVerseTexture.flipY = false;
		this.mangaTexture = this.resources.items.webtoonImageTexture;
		this.mangaTexture.flipY = false;
		this.tabmasterTexture = this.resources.items.tabmasterImageTexture;
		this.tabmasterTexture.flipY = false;
		this.projectXTexture = this.resources.items.projectXImageTexture;
		this.projectXTexture.flipY = false;
	}

	setVideoTextures() {
		this.callistoTextureSource = this.resources.items.callistoTexture;
		this.callistoTexture = this.callistoTextureSource.videoTexture;

		this.driveSafeTextureSource = this.resources.items.driveSafeTexture;
		this.driveSafeTexture = this.driveSafeTextureSource.videoTexture;

		this.evoVerseTextureSource = this.resources.items.evoVerseTexture;
		this.evoVerseTexture = this.evoVerseTextureSource.videoTexture;

		this.cardGamesTextureSource = this.resources.items.cardGamesTexture;
		this.cardGamesTexture = this.cardGamesTextureSource.videoTexture;

		this.mangaTextureSource = this.resources.items.mangaTexture;
		this.mangaTexture = this.mangaTextureSource.videoTexture;

		this.tabmasterTextureSource = this.resources.items.tabmasterTexture;
		this.tabmasterTexture = this.tabmasterTextureSource.videoTexture;

		this.projectXTextureSource = this.resources.items.projectXTexture;
		this.projectXTexture = this.projectXTextureSource.videoTexture;
	}

	changeCardsMaterials() {
		const environment = new RoomEnvironment( this.renderer );
		const pmremGenerator = new THREE.PMREMGenerator( this.renderer );
		const env = pmremGenerator.fromScene( environment ).texture;

		this.model.traverse( ( node ) => {
			if ( node instanceof THREE.Mesh ) {
				if ( node.material instanceof THREE.MeshPhysicalMaterial ) {

					// Callisto
					if ( node.name == 'Cube008_2' ) {
						node.material.emissiveMap = this.callistoTexture;
						node.name = 'Callisto';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'callisto_text' ) {
						node.name = 'Callisto';
					}

					// Drive Safe
					if ( node.name == 'Cube007_2' ) {
						node.material.emissiveMap = this.driveSafeTexture;
						node.name = 'DriveSafe';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'drivesafe_text' ) {
						node.name = 'DriveSafe';
					}

					// Manga Anime
					if ( node.name == 'Cube010_2' ) {
						node.material.emissiveMap = this.mangaTexture;
						node.name = 'Manga';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'mangaanime_text' ) {
						node.name = 'Manga';
					}

					// Evo Verse
					if ( node.name == 'Cube005_2' ) {
						node.material.emissiveMap = this.evoVerseTexture;
						node.name = 'EvoVerse';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'evoverse_text' ) {
						node.name = 'EvoVerse';
					}

					// Card Game
					if ( node.name == 'Cube006_2' ) {
						node.material.emissiveMap = this.cardGamesTexture;
						node.name = 'CardGame';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'card_game_text' ) {
						node.name = 'CardGame';
					}

					// Tabmaster
					if ( node.name == 'Cube002_2' ) {
						node.material.emissiveMap = this.tabmasterTexture;
						node.name = 'TabMaster';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'tabmaster_text' ) {
						node.name = 'TabMaster';
					}

					// Project X
					if ( node.name == 'Cube001_2' ) {
						node.material.emissiveMap = this.projectXTexture;
						node.name = 'Project-X';

						node.frustumCulled = false;
						node.material.onBeforeCompile = Helpers.physicalFresnel;
					}

					if ( node.name == 'projectX_text' ) {
						node.name = 'Project-X';
					}

					node.material.dipersion = 0.1;
					node.material.envMap = env;

					// color: 0xffffff,
					//   transmission: 1,
					//   opacity: 0.0,
					//   metalness: 0,
					//   roughness: 0.17,
					//   ior: 1.5,
					//   thickness: 0.087,
					//   clearcoat: 0.73,
					//   reflectivity: 0.5,
					//   dispersion: 2.16,
					//   attenuationColor: 0xffffff,
					//   attenuationDistance: 1,
					//   specularIntensity: 0,
					//   specularColor: 0xffffff,
					//   envMapIntensity: 1,
					//   transparent: true,


					node.needsUpdate = true;
				}
			}
		} );
	}

	setTransitionTextureResolution() {
		this.imageAspect = this.transitionTexture.image.height / this.transitionTexture.image.width;
		let a1;
		let a2;
		if ( this.sizes.height / this.sizes.width > this.imageAspect ) {
			a1 = ( this.sizes.width / this.sizes.height ) * this.imageAspect;
			a2 = 1;
		} else {
			a1 = 1;
			a2 = ( this.sizes.height / this.sizes.width ) / this.imageAspect;
		}

		this.postProcess.clearPass.uniforms.u_TransitionTextureResolution.value.set( this.transitionTexture.image.width, this.transitionTexture.image.height, a1, a2 );
	}

	setModelDepth() {
		this.experience.world.on( 'ready', () => {
			this.sceneDepth = this.experience.world.sceneDepth;
			this.modelDepth = this.model.clone();
			this.sceneDepth.container.add( this.modelDepth );
		} );
	}

	setAnimations() {
		this.openAnimation = ( _callback = () => {} ) => {
			gsap.to( this, {
				duration: 3,
				transitionProgress: 1.0,
				ease: 'none',
				onStart: () => {
					this.postProcess.clearPass.uniforms.u_ScreenShow.value = true;
				},
				onUpdate: () => {
					this.setTransitionProgress( this.transitionProgress );
				},
				onComplete: () => {
					_callback();
				}
			} );
		};

		this.closeAnimation = ( _callback = () => {} ) => {
			gsap.to( this, {
				duration: 3,
				transitionProgress: -0.2,
				ease: 'none',
				onUpdate: () => {
					this.setTransitionProgress( this.transitionProgress );
				},
				onComplete: () => {
					_callback();
				}
			} );
		};

		this.screenVisible = ( value = false ) => {
			this.postProcess.clearPass.uniforms.u_ScreenShow.value = value;
		};
	}

	playAllVideosAdaptive() {
		if( this.experience.isMobile ) {
			return
		}

		this.playAllVideos()
	}

	playAllVideos() {
		this.callistoTextureSource.videoElement.play();
		this.driveSafeTextureSource.videoElement.play();
		this.mangaTextureSource.videoElement.play();
		this.evoVerseTextureSource.videoElement.play();
		this.cardGamesTextureSource.videoElement.play();
		this.tabmasterTextureSource.videoElement.play();
		this.projectXTextureSource.videoElement.play();
	}

	pauseAllVideos() {
		this.callistoTextureSource.videoElement.pause();
		this.driveSafeTextureSource.videoElement.pause();
		this.mangaTextureSource.videoElement.pause();
		this.evoVerseTextureSource.videoElement.pause();
		this.cardGamesTextureSource.videoElement.pause();
		this.tabmasterTextureSource.videoElement.pause();
		this.projectXTextureSource.videoElement.pause();
	}

	resize() {
		this.setTransitionTextureResolution();
	}

	setDebug() {
		if ( !this.debug.active ) return;

		if ( this.debug.panel ) {
			this.debugFolder = this.debug.panel.addFolder( 'Cards' );
			this.debugFolder.add( this, 'transitionProgress', -0.2, 1, 0.01 )
				.onChange( () => {
					this.setTransitionProgress( this.transitionProgress );
				} );
		}
	}

	setTransitionProgress( progress ) {
		this.transitionProgress = progress;
		this.postProcess.clearPass.uniforms.u_TransitionProgress.value = this.transitionProgress;
		this.experience.world.cube.plane.material.uniforms.u_TransitionProgress.value = this.transitionProgress;
	}

	setEvents() {
		this.experience.on( 'click', ( event, clickPosition ) => {
      this.raycaster.setFromCamera( clickPosition, this.camera );

			// Raycast against the "surface" object.
			const intersects = this.raycaster.intersectObject( this.container );

			if ( intersects.length > 0 ) {
				for (let index = 0; index < intersects.length; index++) {
					let intersectionElem = intersects[index].object;

					if ( this.cardsNames.includes( intersectionElem.name ) ) {
						window.dispatchEvent( new CustomEvent( '3d-add:card-click', { detail: { name: intersectionElem.name } } ) );
						break;
					}
				}

			}

		} );

	}


	update( deltaTime ) {
		//this.model.rotation.y += 0.01

		this.container.position.y = ( -this.scroll.progressSteps[ 1 ] * this.positionScale ) + this.scroll.progress * this.positionScale;

		const deg = Math.PI * 2 - ( Math.PI * 2 / this.cardsCount );

		if ( this.container.position.y > 0 ) {
			this.container.position.y = 0;
			this.container.rotation.y = MathHelper.remap( this.scroll.progress, this.scroll.progressSteps[ 1 ], this.scroll.progressSteps[ 2 ], 0, deg );
		}

		if ( this.scroll.progress > this.scroll.progressSteps[ 2 ] ) {
			this.container.rotation.y = deg;
			this.container.position.y = ( -this.scroll.progressSteps[ 2 ] * this.positionScale ) + this.scroll.progress * this.positionScale;
		}

		if ( this.modelDepth ) {
			this.modelDepth.rotation.set( this.container.rotation.x, this.container.rotation.y, this.container.rotation.z );
			this.modelDepth.position.set( this.container.position.x, this.container.position.y, this.container.position.z );
		}
	}

}
