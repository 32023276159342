import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import ProjectView from '../views/ProjectView.vue';
import FaqView from '../views/FaqView.vue';
import PrivacyPolicyView from '../views/PrivacyPolicyView.vue';
import AboutUsView from '../views/AboutUsView.vue';
import Experience from '@experience/Experience.js';

const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	routes: [
		{
			path: '/project/:slug',
			name: 'Project',
			props: route => ({ slug: route.params.slug }),
			component: ProjectView,
		},
		{
			path: '/faq',
			name: 'FAQ',
			component: FaqView,
		},
		{
			path: '/privacy-policy',
			name: 'Privacy Policy',
			component: PrivacyPolicyView,
		},
		{
			path: '/about-us',
			name: 'About Us',
			component: AboutUsView,
		},
		{
			path: '/',
			name: 'home',
			component: HomeView,
		},
		// {
		// 	path: '/about',
		// 	name: 'about',
		// 	// route level code-splitting
		// 	// this generates a separate chunk (About.[hash].js) for this route
		// 	// which is lazy-loaded when the route is visited.
		// 	component: () => import('../views/AboutView.vue'),
		// },
	],
});

let experience = null;
let ANIMATION_STATE = '';

window.addEventListener('3d-app:ready', () => {
	experience = Experience.getInstance();
});

router.beforeEach((to, from) => {
	if(from.name === 'Project' && to.name === 'home' && experience) {
		switch(ANIMATION_STATE) {
			case 'finished':
				ANIMATION_STATE = '';
				return true
			case '':
				ANIMATION_STATE = 'in_progress'
				experience.world.cards.closeAnimation(() => {
					ANIMATION_STATE = 'finished'
					window.scrollToProject = from.params.slug
					router.push({ name: 'home' })
					setTimeout(() => {
						window.dispatchEvent(new CustomEvent('3d-app:complete-first-animation'))
						experience.world.cards.setTransitionProgress(-0.2)
						experience.world.cards.screenVisible(false)
					}, 100)
				})
				return false
			case 'in_progress':
				return false;			
		}
	} else if (to.name !== 'home' && to.name !== 'Project' && experience) {
		experience?.destroy();
		document.getElementById('three-canvas')?.remove();
		document.getElementById('screenshot-canvas')?.remove();
	} else if (from.name !== 'Project' && to.name === 'home' && experience) {
		window.location.href = '/'
	}
})

export default router;
