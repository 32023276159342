<template>
	<section class="project-info">
		<div class="container">
			<div class="project-info__main">
				<div class="project-info__main-info">				
					<h1 class="project-info__title c-primary font-heading uppercase m-0">
						{{ projectData.title }}
					</h1>

					<div v-for="(item, index) in projectData.info" :key="index" class="project-info__info">
						<h2 class="project-info__info-name uppercase m-0">
							{{ item.title }}
						</h2>

						<p class="project-info__info-description m-0">
							{{ item.text }}
						</p>
					</div>
				</div>

				<div class="project-info__image-wrapper">
					<div class="project-info__image">
						<video
							v-if="projectData.media.video"
							:src="projectData.media.video"
							:poster="projectData.media.poster" 
							disablePictureInPicture="true"
							loop="loop"
							autoplay="true"
							playsinline="true"
							muted="true"
						></video>
						<img v-if="!projectData.media.video && projectData.image.src" :src='projectData.image.src' alt="">
					</div>
				</div>
			</div>

			<div class="project-info__footer">
				<div class="project-info__note-wrapper">
					<div class="project-info__note uppercase">
						<IconMouse v-if="!isMobileDevice" class="icon" />

						<span v-if="!isMobileDevice" class="text">
							SCROLL TO CLOSE
						</span>

						<IconSwipe v-if="isMobileDevice" class="icon" />

						<span v-if="isMobileDevice" class="text">
							SWIPE TO CLOSE
						</span>
					</div>
				</div>

				<div class="project-info__description uppercase">
					<p class="m-0">
						{{ projectData.description }}
					</p>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import IconMouse from '@/components/icons/IconMouse.vue';
import IconSwipe from '@/components/icons/IconSwipe.vue';
import { isMobile } from '@/helpers/isMobile';

export default {
	name: 'projectInfo',
	components: { IconMouse, IconSwipe },
	data: () => ({
		isMobileDevice: false,
		startY: 0,
		scrollElement: null
	}),
	props: {
		projectData: {
			type: Object,
			required: true
		}
	},
	mounted() {
		this.isMobileDevice = isMobile.any();
		
		this.scrollElement = document.documentElement.classList.contains('mobile-scroll') ? document.getElementById('app') : window;
		
		if (this.scrollElement === document.getElementById('app')) {
			this.scrollElement.scrollTop = 0;
		} else {
			window.scrollTo({top: 0, behavior: 'instant'});
		}

		this.$nextTick(() => {
			setTimeout(() => {
				this.scrollElement.addEventListener('scroll', this.handleScroll);
				this.scrollElement.addEventListener('touchstart', this.handleTouchStart, false);
				this.scrollElement.addEventListener('touchend', this.handleTouchEnd, false);
				this.scrollElement.addEventListener('wheel', this.handleWheel);
			}, 100)
		})
	},
	methods: {
		handleScroll() {
			if (this.isScrollAtBottom()) {
				this.scrollElement.removeEventListener('scroll', this.handleScroll);
				this.$router.push({ name: 'home' });
			}
		},

		handleTouchStart(event) {
			this.startY = event.touches[0].clientY;
		},

		handleTouchEnd(event) {
			let endY = event.changedTouches[0].clientY;
			if (this.isScrollAtBottom() && this.startY > endY) {
				this.scrollElement.removeEventListener('touchstart', this.handleTouchStart, false);
				this.scrollElement.removeEventListener('touchend', this.handleTouchEnd, false);
				this.$router.push({ name: 'home' });
			}
		},

		handleWheel(event) {
			if (this.isScrollAtBottom() && event.deltaY > 0) {
				this.scrollElement.removeEventListener('wheel', this.handleWheel);
				this.$router.push({ name: 'home' });
			}
		},

		isScrollAtBottom() {
			if (this.scrollElement === window) {
				return Math.ceil((window.innerHeight + window.scrollY)) >= document.body.offsetHeight;
			} else {
				return Math.ceil((this.scrollElement.scrollTop + this.scrollElement.clientHeight)) >= this.scrollElement.scrollHeight;
			}
		}
	},
	beforeUnmount() {
		this.scrollElement.removeEventListener('scroll', this.handleScroll);
		this.scrollElement.removeEventListener('touchstart', this.handleTouchStart, false);
		this.scrollElement.removeEventListener('touchend', this.handleTouchEnd, false);
		this.scrollElement.removeEventListener('wheel', this.handleWheel);
	},
};
</script>
