import './assets/styles/index.scss';

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { register as registerCustomScroll } from '@/lib/custom-scroll.js';
registerCustomScroll();
import { define as defineGlobalValidators } from '@/helpers/global-validators.js';
defineGlobalValidators();
import { register } from 'swiper/element/bundle';
register();

import App from './App.vue';
import router from './router';

if (document.documentElement.classList.contains('mobile-scroll')) {
	document.getElementById('app').scrollTop = 0;
} else {
	window.scrollTo({top: 0, behavior: 'instant'});
}

const app = createApp(App);

app.use(createPinia());
app.use(router);

app.mount('#app');
